<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onEditAROrder" buttonText="" title="Modifier l'AR de commande">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDownloadAROrder" buttonText="" title="Télécharger" :disabled="!this.params.data.pdfGenerated">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-pdf']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListAROrdersActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onEditAROrder() {
                this.params.context.componentParent.editAROrder(this.params.data);
            },
            onDownloadAROrder() {
                this.params.context.componentParent.downloadARORderPdf(this.params.data.orderDocumentPath);
            }
        }
    }        
</script>