<style scoped>
    
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Créer un AR de commande" @click="displayCreateAROrderModal">>
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar> 
    <Aggrid
        apiRoute="orderDocument/list"
        :apiParams="filter"
        :columnDefs="columnDefs"
        :context="context"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        @rowClicked="((event) => editAROrder(event))"
    >
    </Aggrid>
    <OrderDocumentCreateModal
        mode="arOrder"
        orderDocumentRelatedType="quotation"
        title="CRÉER UN AR DE COMMANDE"
        subtitleDocumentFrom="Basé sur le devis"
        :displayModal="displayModalCreateAROrder"
        @onOrderDocumentCreated="onAROrderCreated"
        @onClose="() => { displayModalCreateAROrder = false }"
    ></OrderDocumentCreateModal>
    <OrderDocumentModal
        v-if="orderDocuments"
        v-model:orderDocuments="orderDocuments"
        v-show="displayOrderDocumentModal"
        :orderDocumentId="currentAROrderId"
        @onClose="closeOrderDocumentModal"
        @onOrderDocumentUpdated="onAROrderUpdated"
    ></OrderDocumentModal>
</template>

<script>
    import CustomTopbar from '../../components/Topbar/CustomTopbar';
    import BaseButton from '../../components/Base/BaseButton';
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ListAROrdersActionButtonsRenderer from './ListAROrdersActionButtonsRenderer';
    import {formatPrice} from "../../utils/formatter";
    import config from "../../config";
    import OrderDocumentModal from '../OrderDocument/OrderDocumentModal';
    import OrderDocumentCreateModal from '../OrderDocument/OrderDocumentCreateModal';
    import axios from 'axios';

    export default {
        name: 'AROrders',
        components: {
            CustomTopbar,
            BaseButton,
            Aggrid,
            ListAROrdersActionButtonsRenderer,
            OrderDocumentModal,
            OrderDocumentCreateModal
        },
        data() {
            return {
                columnDefs: [
                    {field: 'createdAt', headerName: `Date`, flex: 1, lockPosition: true},
                    {field: 'orderDocumentNumber', headerName: `Numéro`, flex: 1, lockPosition: true},
                    {field: 'affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber'},
                    {field: 'billingName', headerName: `Client`, flex: 1, lockPosition: true},
                    {field: 'metadata', headerName : `Établissement`, flex: 2, cellRendererFunctionName: 'EtablissementRenderer', suppressMenu: true },
                    {headerName: '', width: 220, cellRenderer: 'ListAROrdersActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListAROrdersActionButtonsRenderer: ListAROrdersActionButtonsRenderer
                },
                context: null,
                search: '',
                searchTimeout: null,
                filter: {
                    mode: 'arOrder',
                    deleted: false,
                    search: ''
                },
                formatPrice: formatPrice,
                displayModalCreateAROrder: false,
                displayOrderDocumentModal: false,
                orderDocuments: null,
                currentAROrderId: null,
                cellRendererFunctions: new Map([
                    [
                        'EtablissementRenderer',
                        function(param) {
                            if (param.data?.metadata?.establishment === 'smg') {
                                return `<div>SMG</div>`;
                            } else if (param.data?.metadata?.establishment === 'ebc') {
                                return `<div>EBC</div>`;
                            }
                            return '';
                        }
                    ]
                ]),
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        mounted() {
            if (this.$route.query?.action === 'displayOrderDocument') {
                this.editAROrder({
                    id: parseInt(this.$route.query.orderDocumentId),
                    affairId: parseInt(this.$route.query.affairId)
                });
            }
        },
        methods: {
            displayCreateAROrderModal() {
                this.displayModalCreateAROrder = true;
            },
            downloadARORderPdf(arOrderPath) {
                if (arOrderPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${arOrderPath}`, '_blank').focus();
            },
            onAROrderCreated(orderDocuments) {
                this.displayModalCreateAROrder = false;
                this.orderDocuments = orderDocuments;
                this.displayOrderDocumentModal = true;
                this.emitter.emit('resizeHeight');
            },
            closeOrderDocumentModal() {
                this.displayOrderDocumentModal = false;
            },
            onAROrderUpdated() {
                this.emitter.emit('ag-grid-reload');
            },
            editAROrder(arOrder) {
                axios
                .get(`orderDocument/getSameOrderDocuments?mode=arOrder&${arOrder.affairId ? `affairId=${arOrder.affairId}` : `orderDocumentId=${arOrder.id}`}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then(result => {
                    this.currentAROrderId = arOrder.id;
                    this.onAROrderCreated(result.data);
                })
                .catch(() => {});
            }
        }
    }
</script>